<template>
  <!-- action="https://top.freshgo.top/api/v1/adminUploadFileQiniu" -->
  <!-- action="http://localhost:8080/api/v1/adminUploadFileQiniu" -->

  <div class="clearfix">
    <div style="padding-bottom:10px">{{ sizeText }}</div>
    <a-upload action="https://top.freshgo.top/api/v1/adminUploadFileQiniu" :headers="headers" list-type="picture-card"
      :file-list="fileList" :before-upload="beforeUpload" @preview="handlePreview" @change="handleChange">
      <div v-if="fileList.length < totalLength">
        <a-icon type="plus" />
        <div class="ant-upload-text">{{ placeholderText }}</div>
      </div>
    </a-upload>
    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>
<script>
export default {
  props: {
    placeholderText: {
      type: String,
      default: "上传图片",
    },
    totalLength: {
      type: Number,
      default: 1,
    },
    sizeText: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      previewVisible: false,
      previewImage: "",
      fileList: [],
      headers: {
        Authorization: "Bearer " + this.$root.token,
      },
    };
  },
  methods: {
    handleCancel() {
      this.previewVisible = false;
    },
    async handlePreview(file) {
      this.previewImage = file.response.data;
      this.previewVisible = true;
    },
    handleChange({ file, fileList }) {
      if (file.status === 'done') {
        // 上传完成
        this.$emit("onLoadingImg", false);
      }
      fileList.forEach(item => {
        if (item.response != null) {
          item.thumbUrl = item.response.data;
        }
      });
      this.fileList = fileList;
      this.$emit("onChangeImg", this.fileList);
    },
    onSetFileList(list) {
      this.fileList = list;
    },
    beforeUpload() {
      this.$emit("onLoadingImg", true);
    }
  },
};
</script>
<style>
/* you can make up upload button and sample style by using stylesheets */
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>