const ApiRootUrl = 'https://top.freshgo.top/api/v1/'

export default {
    ApiRootUrl,
    adminLogin: 'adminLogin',
    adminCheck: 'adminCheck',
    adminFetchArea: 'adminFetchArea',
    adminUpdateArea: 'adminUpdateArea',
    adminCreateArea: 'adminCreateArea',
    adminFetchAreaAll: "adminFetchAreaAll",

    adminFetchBanner: 'adminFetchBanner',
    adminUpdateBanner: 'adminUpdateBanner',
    adminCreateBanner: 'adminCreateBanner',
    adminFetchUser: "adminFetchUser",
    adminUpdateUser: "adminUpdateUser",
    adminFetchConfig: "adminFetchConfig",
    adminUpdateConfig: 'adminUpdateConfig',
    adminFetchCate: "adminFetchCate",
    adminFetchCateAll: "adminFetchCateAll",
    adminCreateCate: "adminCreateCate",
    adminUpdateCate: "adminUpdateCate",
    adminFetchSecondCateAll: "adminFetchSecondCateAll",
    adminFetchSecondCateAllStatusOn: 'adminFetchSecondCateAllStatusOn',

    adminFetchProductByIds: "adminFetchProductByIds",
    adminFetchProduct: "adminFetchProduct",
    adminUpdateProduct: 'adminUpdateProduct',
    adminFetchProductByName: 'adminFetchProductByName',
    adminCreateProduct: 'adminCreateProduct',

    adminFetchSupport: 'adminFetchSupport',
    adminUpdateSupport: 'adminUpdateSupport',
    adminCreateSupport: 'adminCreateSupport',

    adminFetchOrder: 'adminFetchOrder',
    adminUpdateOrder: 'adminUpdateOrder',
    adminRenewProductByIds: 'adminRenewProductByIds',

    adminUploadFileQiniu: 'adminUploadFileQiniu',
    adminFetchIndexCate: 'adminFetchIndexCate',
    adminUpdateIndexCate: 'adminUpdateIndexCate',
    adminCreateIndexCate: 'adminCreateIndexCate',
    adminCopyProduct: 'adminCopyProduct',



}